@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.s-best-blogs {
    position: relative;

    &_w-bg {
        &::before {
            content: '';

            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transform: skewY(-7deg);

            background-color: $color-laguna-light;
        }
    }
}
.s-best-blogs__bgs {
    height: auto;

    top: -80px;
    bottom: -80px;
}
.s-best-blogs__bgs-item {
    &_top-right {
        width: 300px;
        height: 300px;

        top: 80px;
        right: 0;
        transform: translate(40%, -10%);

        @media (min-width: $small-size) {
            width: 400px;
            height: 400px;
        }
        @media (min-width: $large-size) {
            width: 500px;
            height: 500px;
        }
    }
    &_bottom-left {
        width: 250px;
        height: 250px;

        bottom: 80px;
        left: 0;
        transform: translate(-40%, 10%);

        @media (min-width: $small-size) {
            width: 350px;
            height: 350px;
        }
        @media (min-width: $medium-size) {
            transform: translate(-40%, 20%);
        }
        @media (min-width: $large-size) {
            width: 400px;
            height: 400px;
        }
    }
}


.s-best-blogs__container {
    @media (min-width: $medium-size) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media (min-width: $large-size) {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    &_min-padding {
        padding-bottom: 50px;
    }
}

.s-best-blogs__head {
    margin-bottom: 30px;

    @media (min-width: $small-size) {
        display: flex;
        flex-direction: column;
    }
    @media (min-width: $medium-size) {
        flex-direction: initial;
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-row-gap: 20px;
        grid-column-gap: 20px;

        margin-bottom: 40px;
    }
}
.s-best-blogs__heading {
    margin-bottom: 20px;

    @media (min-width: $medium-size) {
        grid-column: 1 / 2 span;

        margin-bottom: 0;
    }
}
.s-best-blogs__head-text {
    max-width: 670px;
}
.s-best-blogs__head-link {
    margin-top: 30px;

    @media (min-width: $small-size) {
        margin-left: auto;
    }
    @media (min-width: $medium-size) {
        margin-top: 8px;
    }
}
.s-best-blogs__container-list {
    &.swiper {
        margin-right: -20px;
    
        @media (min-width: $smallest-size) {
            margin-right: -32px;
        }
        @media (min-width: $small-size) {
            margin-right: 0;
        }
    }

    &.swiper:not(.swiper-initialized) {
        margin-right: 0;
    }
}

.s-best-blogs__list {
    &.swiper-wrapper {
        @media (min-width: $small-size) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 32px;
            grid-row-gap: 32px;
        }
        @media (min-width: $medium-size) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &.swiper-wrapper:not([style*='transform']) {
        display: grid;
        grid-row-gap: 32px;
    }

    & > * {
        @media (min-width: $small-size) {
            &:last-child:nth-child(2n - 1) {
                grid-column: 1 / 2 span;
                justify-self: center;
    
                width: 49%;
            }
        }
        @media (min-width: $medium-size) {
            &:last-child:nth-child(2n - 1) {
                grid-column: auto;
                justify-self: initial;
    
                width: auto;
            }
        }
    }
}
.s-best-blogs_high-slider {
    .s-best-blogs__list.swiper-wrapper {
        @media (min-width: $small-size) {
            display: flex;
            grid-template-columns: auto;
            grid-column-gap: 0;
            grid-row-gap: 0;
        }
        @media (min-width: $medium-size) {
            grid-template-columns: auto;
        }
    }
    .s-best-blogs__list > * {
        @media (min-width: $small-size) {
            &:last-child:nth-child(2n - 1) {
                grid-column: auto;
                justify-self: initial;
    
                width: auto;
            }
        }
        @media (min-width: $medium-size) {
            &:last-child:nth-child(2n - 1) {
                grid-column: auto;
                justify-self: initial;
    
                width: auto;
            }
        }
    }
}

.s-best-blogs__wr-slider {
    position: relative;
}
.s-best-blogs__slider-controls {
    display: none;

    width: 100%;

    position: absolute;
    top: var(--arrows-position);
    left: 0;
    z-index: 1;

    @media (min-width: $small-size) {
        display: block;
    }
}
.s-best-blogs__arrow {
    width: 32px;
    height: 32px;
    border: 1px solid $color-laguna;

    position: absolute;

    background-color: $color-laguna;
    border-radius: 50%;
    transition: 300ms ease-in-out;
    cursor: pointer;

    @media (min-width: $small-mid-size) {
        width: 36px;
        height: 36px;
    }

    &_prev {
        left: 0;
        transform: translate(-50%, -50%) rotate(-90deg);
    }
    &_next {
        right: 0;
        transform: translate(50%, -50%) rotate(90deg);
    }

    &:hover {
        @media (hover: hover) {
            background-color: #ffffff;
        }

        &::before {
            @media (hover: hover) {
                border-top-color: $color-laguna;
                border-right-color: $color-laguna;
            }
        }
    }

    &::before {
        content: '';

        width: 9px;
        height: 9px;
        border-top: 2px solid #ffffff;
        border-right: 2px solid #ffffff;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -30%) rotate(-45deg);
    
        transition: 300ms ease-in-out;
    }
}